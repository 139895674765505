import * as Sentry from "@sentry/react";
import useFetch from "../../../components/shared/hooks/api/fetch";
import { getHistoryEndpoint } from "../../../services/history/historyService";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import PageContainer from "../../../components/common/PageContainer";
import React, { useState, useEffect } from "react";
import StoreChangesTable from "./StoreChangesTable";
import NoDataAvailable from "../../../components/common/NoDataAvailable";
import Pagination from "../../../components/common/pagination";
import HistoryDiffViewer from "../HistoryDiffViewer";
import Loader from "../../../components/common/Loader";

function StoreHistory() {
  const history = useHistory();
  const { storeLocation } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [showDiffView, setShowDiffView] = useState(false);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(0);

  const pageSize = 10;
  const historyType = "stores";
  const [data, requestState] = useFetch(getHistoryEndpoint(historyType, storeLocation));

  const [storeHistories, setStoreHistories] = useState([]);
  const [paginatedHistories, setPaginatedHistories] = useState([]);

  useEffect(() => {
    if (data) {
      setStoreHistories(data);
      updatePaginatedHistories(data, currentPage);
    }
  }, [data, currentPage]);

  const updatePaginatedHistories = (histories, page) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginatedHistories(histories.slice(startIndex, endIndex));
  };

  const navigateBack = () => {
    if (showDiffView) {
      setShowDiffView(false);
    } else {
      history.go(-1);
    }
  };

  const onPageChanged = (page) => {
    setCurrentPage(page);
    updatePaginatedHistories(storeHistories, page);
  };

  const handleSeeChanges = (index) => {
    setSelectedHistoryIndex(index);
    setShowDiffView(true);
  };

  const onPreviousClicked = () => {
    setSelectedHistoryIndex((prevIndex) => Math.min(prevIndex + 1, storeHistories.length - 1));
  };

  const onNextClicked = () => {
    setSelectedHistoryIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  if (requestState.isLoading) {
    return <Loader />;
  }

  if (!paginatedHistories || paginatedHistories.length === 0) {
    return <NoDataAvailable />;
  }

  return (
    <PageContainer>
      <Container fluid>
        <Card>
          <CardBody>
            <Row>
              <Col md={{ size: 2 }}>
                <Button onClick={navigateBack}>Go back</Button>
              </Col>
            </Row>
            {!showDiffView ? (
              <>
                <Row>
                  <StoreChangesTable
                    storeLocation={storeLocation}
                    storeHistories={paginatedHistories}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onSeeChanges={handleSeeChanges}
                  />
                </Row>
                <Row>
                  <Pagination
                    label="changes"
                    itemsCount={storeHistories.length}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={onPageChanged}
                  />
                </Row>
              </>
            ) : (
              <HistoryDiffViewer
                historyData={storeHistories}
                currentIndex={selectedHistoryIndex}
                onPreviousClicked={onPreviousClicked}
                onNextClicked={onNextClicked}
                storeName={storeLocation}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </PageContainer>
  );
}

export default Sentry.withProfiler(StoreHistory);
