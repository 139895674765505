import * as Sentry from "@sentry/react";
import { Button, Col, Row, Table } from "reactstrap";
import React from "react";
import formatDate from "./utils";

function HistoryDiffViewer({ historyData, currentIndex, onPreviousClicked, onNextClicked, storeName, username }) {
  const renderMetadata = (historyItem) => {
    return (
      <div className="bg-gray-700/50 p-4 rounded mb-4">
        <Row>
          <Col md={4}>
            <p>
              <strong className="text-gray-400">Location:</strong>{" "}
              <span className="text-gray-300">{historyItem.location}</span>
            </p>
          </Col>
          <Col md={4}>
            <p>
              <strong className="text-gray-400">User:</strong>{" "}
              <span className="text-gray-300">{historyItem.user__username}</span>
            </p>
          </Col>
          <Col md={4}>
            <p>
              <strong className="text-gray-400">Date:</strong>{" "}
              <span className="text-gray-300">{formatDate(historyItem.created_at)}</span>
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  const renderAction = (historyData) => {
    return (
      <div className="bg-gray-700/50 p-4 rounded-lg mb-4 shadow flex items-center justify-between">
        <div className="text-gray-300 text-lg">
          <span className="uppercase font-bold">{historyData.action}</span>
          <span>
            {" "}
            - {historyData.updated_table} - {historyData.instance_repr || historyData.table_pk}
          </span>
        </div>
        <div className="bg-gray-700 text-gray-300 rounded-full px-3 py-1 text-sm font-bold">ACTION</div>
      </div>
    );
  };

  const renderFieldChanges = (historyItem) => {
    if (!historyItem.updated_fields || historyItem.action === "delete") {
      return null;
    }

    return (
      <div className="mt-4 bg-gray-800 rounded overflow-hidden">
        <Table className="mb-0" borderless dark>
          <thead className="bg-gray-700">
            <tr>
              <th className="text-gray-300 font-semibold py-3 px-4">UPDATED FIELDS</th>
              <th className="text-gray-300 font-semibold py-3 px-4">OLD VALUE</th>
              <th className="text-gray-300 font-semibold py-3 px-4">NEW VALUE</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(historyItem.updated_fields).map(([field, values]) => (
              <tr key={field} className="border-t border-gray-700">
                <td className="py-3 px-4 text-gray-400">{field}</td>
                <td className="py-3 px-4 text-gray-400">{values[0] === null ? "None" : values[0]}</td>
                <td className="py-3 px-4 text-gray-400">{values[1] === null ? "None" : values[1]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderChangeMessage = () => {
    const changeNumber = historyData.length - currentIndex;
    const totalChanges = historyData.length;

    const renderCommonMessage = (additionalInfo = "") => (
      <p className="text-white mb-0">
        Change <span className="text-warning font-weight-bold">{changeNumber}</span> of{" "}
        <span className="text-warning font-weight-bold">{totalChanges}</span>
        {additionalInfo && (
          <>
            {" "}
            {additionalInfo}
            <span className="font-weight-bold">{storeName || username}</span>
          </>
        )}
      </p>
    );

    if (storeName) {
      return renderCommonMessage("for store ");
    }
    if (username) {
      return renderCommonMessage("by user ");
    }
    return renderCommonMessage();
  };

  return (
    <>
      <Row className="mb-4 items-center">
        <Col md={6} className="text-center">
          {historyData && historyData.length > 0 && renderChangeMessage()}
        </Col>
        <Col md={6} className="text-right">
          {historyData && historyData.length > 0 && (
            <>
              <Button
                id="previous-button"
                className="mr-2 bg-gray-700 hover:bg-gray-600 text-gray-300"
                disabled={currentIndex >= historyData.length - 1}
                onClick={onPreviousClicked}
              >
                <i className="fa fa-arrow-left" /> Previous
              </Button>
              <Button
                id="next-button"
                className="bg-gray-700 hover:bg-gray-600 text-gray-300"
                disabled={currentIndex <= 0}
                onClick={onNextClicked}
              >
                Next <i className="fa fa-arrow-right" />
              </Button>
            </>
          )}
        </Col>
      </Row>
      {historyData && historyData.length > 0 && (
        <div>
          {renderMetadata(historyData[currentIndex])}
          {renderAction(historyData[currentIndex])}
          {renderFieldChanges(historyData[currentIndex])}
        </div>
      )}
    </>
  );
}

export default Sentry.withProfiler(HistoryDiffViewer);
